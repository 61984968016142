import React from "react";

function Speaker() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            style={{ margin: "auto" }}
            display="block"
            viewBox="0 0 211 319"
        >
            <defs>
                <linearGradient id="a" x1="2.8%" x2="97.5%" y1="55.9%" y2="55.9%">
                    <stop offset="0%" stopColor="#FFFDFA"></stop>
                    <stop offset="8.1%" stopColor="#FFFDFB"></stop>
                    <stop offset="20.2%" stopColor="#FFFDFA"></stop>
                    <stop offset="32.1%" stopColor="#FDFBF8"></stop>
                    <stop offset="43.1%" stopColor="#F9F7F5"></stop>
                    <stop offset="51.8%" stopColor="#F5F4F2"></stop>
                    <stop offset="58.9%" stopColor="#EEECEA"></stop>
                    <stop offset="64.6%" stopColor="#EBE7E7"></stop>
                    <stop offset="71%" stopColor="#E3E0DE"></stop>
                    <stop offset="77.5%" stopColor="#E2DFDD"></stop>
                    <stop offset="84.1%" stopColor="#E7E4E2"></stop>
                    <stop offset="90.7%" stopColor="#EBE8E6"></stop>
                    <stop offset="94.7%" stopColor="#EDEBE9"></stop>
                    <stop offset="100%" stopColor="#EEECEA"></stop>
                </linearGradient>
                <linearGradient id="b" x1="8.9%" x2="87.8%" y1="49.5%" y2="50.7%">
                    <stop offset="0%" stopColor="#FAF9F8"></stop>
                    <stop offset="24.2%" stopColor="#FAF9F8"></stop>
                    <stop offset="66.7%" stopColor="#FAF9F8"></stop>
                    <stop offset="100%" stopColor="#FDFCFA"></stop>
                </linearGradient>
                <linearGradient id="c" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#F5F4F3"></stop>
                    <stop offset="100%" stopColor="#E9E8E7"></stop>
                </linearGradient>
                <linearGradient id="h" x1="6.3%" x2="95.3%" y1="35.1%" y2="35.1%">
                    <stop offset="0%" stopColor="#DCDCDC"></stop>
                    <stop offset="30.8%" stopColor="#C9C9C9"></stop>
                    <stop offset="68.6%" stopColor="#929292"></stop>
                    <stop offset="100%" stopColor="#B9B9B9"></stop>
                </linearGradient>
                <linearGradient id="k" x1="3.4%" x2="95.7%" y1="49.8%" y2="49.7%">
                    <stop offset="0%" stopColor="#545152"></stop>
                    <stop offset="9.4%" stopColor="#6A6463"></stop>
                    <stop offset="20%" stopColor="#393535"></stop>
                    <stop offset="35.2%" stopColor="#1E1E1E"></stop>
                    <stop offset="61.4%" stopColor="#100F0F"></stop>
                    <stop offset="85.5%" stopColor="#171515"></stop>
                    <stop offset="100%" stopColor="#222221"></stop>
                </linearGradient>
                <linearGradient id="n" x1="1.6%" x2="94.5%" y1="49.4%" y2="51.1%">
                    <stop offset="0%" stopColor="#464646"></stop>
                    <stop offset="100%"></stop>
                </linearGradient>
                <radialGradient
                    id="d"
                    cx="50%"
                    cy="50%"
                    r="50%"
                    fx="50%"
                    fy="50%"
                    gradientTransform="matrix(0 1 -.35056 0 .7 0)"
                >
                    <stop offset="0%" stopColor="#BDDDFF"></stop>
                    <stop offset="100%" stopColor="#B0D1F7"></stop>
                </radialGradient>
                <radialGradient
                    id="e"
                    cx="50%"
                    cy="51.2%"
                    r="48.8%"
                    fx="50%"
                    fy="51.2%"
                    gradientTransform="matrix(0 1 -.35056 0 .7 0)"
                >
                    <stop offset="0%" stopColor="#F59D82"></stop>
                    <stop offset="100%" stopColor="#F17847"></stop>
                </radialGradient>
                <radialGradient
                    id="f"
                    cx="50%"
                    cy="50%"
                    r="50%"
                    fx="50%"
                    fy="50%"
                    gradientTransform="matrix(0 1 -.35056 0 .7 0)"
                >
                    <stop offset="0%" stopColor="#FEEB6F"></stop>
                    <stop offset="100%" stopColor="#FBE140"></stop>
                </radialGradient>
                <radialGradient
                    id="g"
                    cx="50%"
                    cy="51.5%"
                    r="48.5%"
                    fx="50%"
                    fy="51.5%"
                    gradientTransform="matrix(0 1 -.35056 0 .7 0)"
                >
                    <stop offset="0%" stopColor="#AEF7CD"></stop>
                    <stop offset="100%" stopColor="#83F5B7"></stop>
                </radialGradient>
                <path
                    id="i"
                    d="M3 185.2l-3 46a91.8 91.8 0 0021 63c11 6.9 41.9 10.5 92.4 10.8 35.5-.7 61.3-4.1 77.3-10.2a98.4 98.4 0 0020.2-63.8c-.8-15.6-1.7-31-2.8-46-8.5 4.9-43.6 7.3-105.4 7.3-61.8 0-95-2.3-99.8-7z"
                ></path>
                <path
                    id="l"
                    d="M20 293c20.5 8.1 51.4 12 92.9 11.5 41.4-.4 67.8-4 79.1-11a76.7 76.7 0 01-13.4 14.7c-4.8 3.5-19.4 9.8-70.7 9.8-34.2 0-57.6-2.7-70.2-8.2-1.9-.6-4.3-2.3-7.2-5.2-3-2.8-6.5-6.7-10.5-11.6z"
                ></path>
                <pattern
                    id="j"
                    width="313.8"
                    height="125.1"
                    x="-313.8"
                    y="59.9"
                    patternUnits="userSpaceOnUse"
                >
                    <use transform="scale(.74)" xlinkHref="#a"></use>
                </pattern>
                <filter
                    id="m"
                    width="101.6%"
                    height="107.4%"
                    x="-.8%"
                    y="-4.4%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetInner1"
                    ></feOffset>
                    <feComposite
                        in="shadowOffsetInner1"
                        in2="SourceAlpha"
                        k2="-1"
                        k3="1"
                        operator="arithmetic"
                        result="shadowInnerInner1"
                    ></feComposite>
                    <feColorMatrix
                        in="shadowInnerInner1"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
                    ></feColorMatrix>
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(3)">
                    <path
                        fill="url(#a)"
                        d="M11.3 26L.8 185.8c3.2 4.4 37.3 6.6 102.4 6.6 65.1 0 99.3-2.4 102.5-7.2L193 26H11.3z"
                    ></path>
                    <ellipse
                        cx="102.2"
                        cy="27.3"
                        fill="url(#b)"
                        rx="90.9"
                        ry="27.3"
                    ></ellipse>
                    <rect
                        width="4.1"
                        height="1"
                        x="176.4"
                        y="25.3"
                        fill="url(#c)"
                        rx="0.5"
                    ></rect>
                    <rect
                        width="4.1"
                        height="1"
                        x="20.3"
                        y="25.3"
                        fill="url(#c)"
                        rx="0.5"
                    ></rect>
                    <ellipse
                        cx="102"
                        cy="17.8"
                        fill="url(#d)"
                        rx="4.5"
                        ry="1.6"
                    ></ellipse>
                    <ellipse
                        cx="125"
                        cy="24.6"
                        fill="url(#e)"
                        rx="4.5"
                        ry="1.6"
                    ></ellipse>
                    <ellipse
                        cx="102"
                        cy="31.4"
                        fill="url(#f)"
                        rx="4.5"
                        ry="1.6"
                    ></ellipse>
                    <ellipse
                        cx="78.9"
                        cy="24.6"
                        fill="url(#g)"
                        rx="4.5"
                        ry="1.6"
                    ></ellipse>
                </g>
                <use fill="url(#h)" xlinkHref="#i"></use>
                <use fill="url(#j)" fillOpacity="0.2" xlinkHref="#i"></use>
                <use fill="url(#k)" xlinkHref="#l"></use>
                <use fill="#000" filter="url(#m)" xlinkHref="#l"></use>
                <use stroke="url(#n)" strokeWidth="0.5" xlinkHref="#l"></use>
            </g>
        </svg>
    );
}

export default Speaker;
