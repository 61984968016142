import React from 'react';
import './../assets/stylesheets/components/assistant.scss';
import Speaker from "./Speaker"

function Assistant(props) {
    return (
        <section className="assistantSection">
            <div className="speakerSVG">
                <Speaker />
            </div>

            <div>
                <div className="assistantText"><h3>Google Assistant app</h3>

                    <p>Following trends is the thing I like most. That’s also why I’m always trying to try
                out builing utilities for new devices, and services. Like the Google Assistant app I made.</p></div>


                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 659 141" className="bubbles">
                    <g fill="none" fillRule="evenodd">
                        <path
                            fill="#F2F2F2"
                            stroke="#D2D2D2"
                            d="M1 1h328c10.493 0 19 8.507 19 19v17c0 10.493-8.507 19-19 19h-31.023 0H20C9.507 56 1 47.493 1 37V1h0z"
                        ></path>
                        <path
                            fill="#FFF"
                            stroke="#D2D2D2"
                            d="M639 140H260c-10.493 0-19-8.507-19-19v-17c0-10.493 8.507-19 19-19h396a2 2 0 012 2v34c0 10.493-8.507 19-19 19z"
                        ></path>
                        <text
                            fill="#3A3939"
                            fontFamily="freight-sans-pro, FreightSans Pro"
                            fontSize="20"
                            fontWeight="400"
                            letterSpacing="-0.383"
                            transform="translate(1 1)"
                        >
                            <tspan x="14" y="34">
                                Hey Google, open the Technology Quiz
          </tspan>
                        </text>
                        <text
                            fill="#3A3939"
                            fontFamily="freight-sans-pro, FreightSans Pro"
                            fontSize="20"
                            fontWeight="400"
                            letterSpacing="-0.383"
                            transform="translate(1 1)"
                        >
                            <tspan x="258" y="118">
                                Sure, opening the Technology quiz by Dirk Mulder
          </tspan>
                        </text>
                    </g>
                </svg>
            </div>

        </section>
    )
}
export default Assistant

