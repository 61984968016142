import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import './../assets/stylesheets/components/columns.scss'
import Img from "gatsby-image";
import Blurrynight from "./Blurrynight";
import NLStemt from "./NLStemt";


export default () => (
    <StaticQuery
        query={graphql`
            query campagne {
                campagne: file(relativePath: { eq: "leiden.png" }) {
                  childImageSharp {
                    fluid(maxWidth: 1240) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                  }
                }
              }
          `}

        render={data => (
            <section className="columnsSection">
                <div className="card">
                    <Img fluid={data.campagne.childImageSharp.fluid} objectFit="cover" imgStyle={{ objectPosition: "center right" }} />
                    <div className="card-content">
                        <h3>Visit Leiden Web App</h3>
                        <p>This is a mobile-first touch friendly webapp to discover the city of Leiden in the Netherlands. This demo demonstrates the usage of a city discovering app via your mobile web browser.</p>
                        <a href="https://leiden.dirk.app" target="_blank" rel="noopener noreferrer">Learn more...</a>
                    </div>
                </div>

                <div className="card">
                    <Blurrynight />
                    <div className="card-content">
                        <h3>Blurry Night Theme</h3>
                        <p>I created the Blurry Night Theme for the Opera browser a few years back while experimenting with the them API. The theme has been downloaded more than 650.000 times since 2016 and has a 4.5 start rating in the Opera Store.</p>
                        <a href="https://addons.opera.com/nl/wallpapers/details/blurry-night-theme/" target="_blank" rel="noopener noreferrer">Learn more...</a>
                    </div>
                </div>

                <div className="card">
                    <NLStemt />
                    <div className="card-content">
                        <h3>NL Stemt</h3>
                        <p>The NL stemt app was an app I created for the 2017 general elections in the Netherlands. I created this app for people to make a good vote. The app was completely free to download and the most downloaded Android app for the 2017 elections.</p>
                        <a href="https://play.google.com/store/apps/details?id=nl.techvandaag.verkiezingen" target="_blank" rel="noopener noreferrer">Learn more...</a>
                    </div>
                </div>

            </section>
        )}
    />
)   