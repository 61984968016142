import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import './../assets/stylesheets/components/leiden.scss'
import Img from "gatsby-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";



export default function President() {
    let bgTransition = '#edf2f7'
    if (typeof window !== 'undefined') {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            bgTransition = '#313233'
        } else {
            bgTransition = '#edf2f7'
        }
    }

    return (
        <StaticQuery
            query={graphql`
        query leidenQuery {
            IndexQuery: file(relativePath: { eq: "president.png" }) {
              childImageSharp {
                fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
              }
            }
          }
      `}
            render={data => (
                <section className="leidenSection">
                    <div>
                        <Img fluid={data.IndexQuery.childImageSharp.fluid} imgStyle={{ objectPosition: "center right", }} />
                    </div>
                    <div>
                        <h3>Président Dairy</h3>
                        <p>President is one of the projects I'm most happy with. I made this website for the international company of President. I did the front-end for the Hong Kong, Singapore, Chinese and Vietnamese website</p>
                        <AniLink cover direction="left"
                            duration={0.7} bg={bgTransition} to="/portfolio/president" activeClassName="active">Learn more...</AniLink>
                    </div>
                </section>
            )}
        />
    )
}