import React from 'react'
import './../assets/stylesheets/components/name.scss'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

function Name(props) {
  let bgTransition = '#edf2f7'

  if (typeof window !== 'undefined') {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      bgTransition = '#313233'
    } else {
      bgTransition = '#edf2f7'
    }
  }

  return (
    <section className="nameSection">
      <h1>Hi, I'm Dirk Mulder. An interaction Designer living in Saigon</h1>
      <h4>
        Check out my work in this{' '}
        <AniLink
          cover
          direction="left"
          duration={0.7}
          bg={bgTransition}
          to="/portfolio"
        >
          <span>portfolio</span>
        </AniLink>
      </h4>
    </section>
  )
}
export default Name
