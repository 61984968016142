import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import './../assets/stylesheets/components/columns.scss'
import Img from "gatsby-image";


export default () => (
  <StaticQuery
    query={graphql`
            query blurrynight {
                blurrynight: file(relativePath: { eq: "blurrynight.jpg" }) {
                  childImageSharp {
                    fluid(maxWidth: 1240) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                  }
                }
              }
          `}

    render={data => (
      <Img fluid={data.blurrynight.childImageSharp.fluid} objectFit="cover" imgStyle={{ objectPosition: "center right" }} />
    )}
  />
)   