import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import './../assets/stylesheets/components/leiden.scss'
import Img from "gatsby-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";



export default function Baigur() {
    let bgTransition = '#edf2f7'

    if (typeof window !== 'undefined') {

        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            bgTransition = '#313233'
        } else {
            bgTransition = '#edf2f7'
        }
    }

    return (
        <StaticQuery
            query={graphql`
        query baigurQuery {
            IndexQuery: file(relativePath: { eq: "baigur_home.png" }) {
              childImageSharp {
                fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
              }
            }
          }
      `}
            render={data => (
                <section className="leidenSection">
                    <div>
                        <Img fluid={data.IndexQuery.childImageSharp.fluid} imgStyle={{ objectPosition: "center right", }} />
                    </div>
                    <div>
                        <h3>Saigon Baigur</h3>
                        <p>Saigon Baigur is a newly introduced gin brand in Vietnam. I did the front-end on this amazingly looking website.</p>
                        <AniLink cover direction="left"
                            duration={0.7} bg={bgTransition} to="/portfolio/baigur" activeClassName="active">Learn more...</AniLink>
                    </div>
                </section>
            )}
        />
    )
}