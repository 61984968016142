import React from "react";
import Name from "./../components/Name";
import Baigur from "./../components/Baigur";
import President from "./../components/President"
import Assistant from "./../components/Assistant"
import Columns from "./../components/Columns"
import Quote from "./../components/Quote"
import Layout from "../components/Layout";

import { Helmet } from "react-helmet"

const IndexPage = ({ data }) => (
  <Layout >
    <Helmet title="Dirk Mulder Portfolio | Home">
      <meta charSet="utf-8" />
      <meta name="description" content="This the portfolio of Dirk Mulder. On this website you can find my latest work and read more about Dirk Mulder." />
      <meta name="keywords" content="Dirk, Mulder, Portfolio" />

      <html lang="en" />
    </Helmet>
    <Name />
    <President />
    <Baigur />
    <Assistant />
    <Columns />
    <Quote />
  </Layout>
);

export default IndexPage;

// export const pageQuery = graphql`
//   query IndexQuery {

//     bgImg: file(relativePath: { eq: "leiden.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 1240) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `;