import React from 'react';
import './../assets/stylesheets/components/quote.scss'

function Name(props) {
    return (
        <section className="quoteSection">
            <h3> NL Stemt - Elections 2017 brings all the political news of NOS and NU.nl and videos, an overview of all political parties, their views and the latest polls. [...] The app is very complete and informative. </h3>
            <h6><a href="https://androidworld.nl/apps/5-apps-om-je-voor-te-bereiden-op-de-tweede-kamerverkiezigen" target="_blank" rel="noopener noreferrer">- Androidworld (translated from Dutch)</a></h6>
        </section>
    )
}
export default Name